import { Center, Heading, useBreakpointValue,Button,Box,Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "./Context/AuthContext";

import CssBaseline from '@mui/material/CssBaseline';
import ColorModeSelect from "./Components/MatSignIn/ColorModeSelect";
import AppTheme from "./Components/MatSignIn/AppTheme";



function NotFoundPage({toggleProp}){

    let navigate = useNavigate()
    let {darkMode, setDarkMode} = useContext(MyContext)


    let mobileView = useBreakpointValue({
        base: true,
        md: false
    })

    let tabletView = useBreakpointValue({
        md: true,
        lg: false
    })



    return(
        <div>
        
            <Center minHeight="100vh"  backgroundColor={toggleProp && "#18181b"} color={toggleProp && "white"}>
                <VStack>
                <Heading fontSize={tabletView? "7xl": (mobileView? "4xl": "8xl")}>404 - Not Found</Heading>
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")} >The page you are looking for does not exist.</Heading>
                <Text mt={5} fontSize={tabletView? "xl":(mobileView? "sm": "2xl")}>But don't worry, you can find plenty of other things on our </Text>
                <Text fontSize={tabletView? "xl": (mobileView? "sm":"2xl")} mt={-4}>homepage.</Text>

                <Button mt={4} colorScheme={darkMode? "gray":"facebook"} onClick={(e)=> navigate("/") } >Back to homepage</Button>
                

                </VStack>
            </Center>

            <div>              
              {/* Purposely for Material Ui theme */}
               <AppTheme>
                <CssBaseline />
                <ColorModeSelect />
              </AppTheme> 
            </div>

        </div>
    )
}


export default NotFoundPage