import { useContext } from "react";
import { MyContext } from "../Context/AuthContext";
import { Navigate } from "react-router-dom";



function ProtectedRoute({children}){

    let {isAuthenticated, setIsAuthenticated} = useContext(MyContext) 


    if (!isAuthenticated)

    return(
        <div>
            <Navigate to="/signin" replace />
        </div>
    )

    else{
        return children
    }
}



export default ProtectedRoute