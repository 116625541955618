import React, {createContext, useState} from "react"



export let MyContext = createContext()


function MyContextProvider({children}){

    let [isAuthenticated, setIsAuthenticated] = useState(false)
    let [darkMode, setDarkMode] = useState(false)


    return(
        <div>
            <MyContext.Provider value={{isAuthenticated, setIsAuthenticated, darkMode, setDarkMode}} >
                {children}
            </MyContext.Provider>
        </div>
    )
}



export default MyContextProvider