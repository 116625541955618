//Import css
import "./Page.css"

import { Heading, useBreakpointValue, CCard,Center, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel } from "@chakra-ui/react"
import {faEye, faEyeSlash, faWarning} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGithub} from "@fortawesome/free-brands-svg-icons"
import XIcon from "@mui/icons-material/X"
import AIBlogBlue from "./Assets/AIBlog.png"
import Loadspinner from "./Components/Loadspinner";
import React, {useState, useEffect, useContext} from "react"
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'



import CssBaseline from '@mui/material/CssBaseline';
import ColorModeSelect from "./Components/MatSignIn/ColorModeSelect";
import Stack from '@mui/material/Stack';
import Content from "./Components/Content";
import SignIn from "./Components/SignIn";
import SignInSide from "./Components/Signin-side/SignInSide"
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from "react-router-dom";

import { GoogleIcon, FacebookIcon, SitemarkIcon } from './Components/MatSignIn/CustomIcons';
import { Box as BBox, Divider, Button as BButton, TextField, FormLabel as MFormLabel, Card} from "@mui/material";
import AIBlog from "./Assets/AI-Blog-Generator-Logo.png"
import { useLocation } from "react-router-dom";




import { useNavigate } from "react-router-dom";
import { MyContext } from "./Context/AuthContext";

import axios from 'axios'
import AppTheme from "./Components/MatSignIn/AppTheme";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import useResetAuth from "./Components/ResetAuth";






function Signin({successLogin, setsuccessLogin, selected, setSelected}){

  useResetAuth()


  let {isAuthenticated, setIsAuthenticated} = useContext(MyContext)
  let {darkMode, setDarkMode} = useContext(MyContext)


  let navigate = useNavigate()
  let [alerterrorMessage, setAlerterrormessage] = useState("")
  let [alerterrorResponseMessage, seterrorResponseMessage] = useState("")
  let [visiblePass, setVisiblePass] = useState(false)

  

  let onClose = () => {
    setAlerterrormessage("")
  }


  let [initialValues, setInitialvalues] = useState({
    username: "",
    password: "",
    rememberMe: false
  })


  let validationSchema = Yup.object({
    username: Yup.string().required("Username required"),
    password: Yup.string().required("Password required"),
    rememberMe: Yup.string()
  })

  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue ? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken();


  let onSubmit = async(values, actions) =>{

    try{  

      let response= await axios.post("https://api.blogai.benasenso.com/djoser/token/login/", values, {
        headers:{
          'X-CSRFToken': csrftoken
        }
      })
      console.log("Signed in successfully", response.data.auth_token)
      //console.log("Signed in successfully", response)
      setIsAuthenticated(true)
      setsuccessLogin(response.data.auth_token)
      //storing user data in the browser's local storage
      values.rememberMe && localStorage.setItem("UserLogin", JSON.stringify(values))
      localStorage.setItem("UserAuthtoken", JSON.stringify(response.data.auth_token) )
      actions.setSubmitting(false)
      navigate("/dashboard", {replace: true})
      setSelected("Dashboard")
      actions.resetForm()
    }
    
    catch (error){
      console.error("There was an error signing in:", error.message)
      actions.setSubmitting(false)
      setAlerterrormessage(`Something went wrong, please try again later! ${error.message}`)
      seterrorResponseMessage(error.response.data.non_field_errors)
    }
  }




  // Getting the user data from the browser's local storage
  
  useEffect((e)=>{
    let storedData = localStorage.getItem("UserLogin")
    // Converting stored data back to Javascript object
    if (storedData){
    let parseUserData = JSON.parse(storedData)
    initialValues.username = parseUserData.username
    initialValues.password = parseUserData.password

    let storedUsername = parseUserData.username
    let storedPassword = parseUserData.password

    }

 },[])



  // Access location object
  const location = useLocation()  // Current location path in terms of URL. The location is an object like this eg. {pathnamme:"/dashboard", search:"?token=abc134", hash:"", state:null}
  
  // URLSearchParams is for query parameter (?token=xhsdb3843) unlike useParams which is to extract dynamic segments from the URL path
  // URLSearchParams expects/takes query parameter in its parantheses. It will turn it into object-like structure
  let query_params = new URLSearchParams(location.search)  // Thus the search value in the location object
  let DRftoken = query_params.get('token') // Get/Extract the value of the token of the query parameter

  let [loadSpinnner, setLoadSpinner] = useState()


 useEffect((e)=>{
    let func = async(e)=>{   
    if (DRftoken){
      setLoadSpinner(true)
      localStorage.setItem("UserAuthtoken", JSON.stringify(DRftoken) )
      // Remove the token from the URL
      query_params.delete("token")
      navigate({searh:"" })

    // For extra security
    try{
      let response = await axios.get("https://api.blogai.benasenso.com/djoser/users/me/", {
        headers:{
          'X-CSRFToken': csrftoken,
          'Authorization': `token ${DRftoken}`
        }
      })
      console.log(`Token stored:${DRftoken}`,response.data)
      if (response.data.username){
      setIsAuthenticated(true)
      setsuccessLogin(DRftoken)
      setLoadSpinner(false)
      navigate("/dashboard", {replace:true})
      setSelected("Dashboard")
      }
    }
    catch(error){
      console.error("Error signing in with social provider")
      setLoadSpinner(false)
      setAlerterrormessage(`Something went wrong, please try again later! ${error.message}`)
    }
  }
}

  func()

 }, [])



// This is just displaying user data on signin page for displaying purposes
//  useEffect(()=>{

//   let storedData = localStorage.getItem("UserAuthtoken")
//   let parseUserData = JSON.parse(storedData)
//   setToken(parseUserData)

//   let funct = async ()=>{
//    if (token){
//     try{
//       const response = await axios.get(" https://api.blogai.benasenso.com/djoser/users/me/", {
//         headers: {
//           'Authorization': `token ${token}`,
//           'X-CSRFToken': csrftoken
//         }
//       })
//       setUser(response.data)
//       console.log("success fetching:", response.data)
//     }
//     catch(error){
//       console.error("Error fetching data", error)
//     }
//   }
//   }

//   funct();

//  }, [token, successLogin])


  let mobileView = useBreakpointValue({
    base: true,
    md: false
  })

  
  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg:false
  }) 



  if (loadSpinnner){
    return <Loadspinner />
  }


    return(
          <div>
              <VStack maxWidth="full" minHeight="100vh" pt={20} pb={20} alignItems="left" spacing={8} backgroundColor={!darkMode&& "gray.100"} backgroundImage={darkMode&& "radial-gradient(at 70% 51%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))"} >  

             <HStack flexDirection={mobileView&& "column-reverse"} m={!mobileView&& "auto"} width={mobileView?"":(tabletView?"100%":"80%")} justifyContent={!mobileView&& "center"} >
              
              <Box mt={mobileView&& 10} ml={(!mobileView&&!tabletView)&& -10} className="flex items-center justify-center flex-grow container">
              <AppTheme>
              <Content />
              </AppTheme>
              </Box>

            
            <div className="flex items-center justify-center min-h-screen flex-grow container">
              <div style={{border:darkMode&& "1px solid  hsl(220, 20%, 25%, 0.9)", backgroundImage:darkMode&& "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))", backgroundColor:darkMode? "#000":"white"}} className=" p-8 shadow-lg rounded-lg max-w-md w-full" >

              {/* <Heading marginBottom={8} fontSize="2xl">Log in to your account </Heading> */}
              
              {/* <img src={AIBlogBlue} style={{width: "150px", padding:0,marginTop:-6}} /> */}
                
              <AppTheme>
              {/* <SitemarkIcon /> */}
            
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', marginTop:-0.5, marginBottom:3, fontWeight:"bold"}}
          >
            Sign in                       {/*Sign in to your account*/}
          </Typography>
          </AppTheme>

                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form>
                  
                <FormControl isInvalid={!!formik.errors.username && formik.touched.username} >
               <AppTheme> <MFormLabel htmlFor="username">Username</MFormLabel> </AppTheme>
                <AppTheme>
                <Field as={TextField} fullWidth autoFocus placeholder="Enter your username..."  name="username" id="username"  />
                </AppTheme>
                <FormErrorMessage fontSize="small">
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="username" />
                </FormErrorMessage>

                </FormControl>
                
 
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mt={4} >
               
              <AppTheme> <MFormLabel htmlFor="password">Password</MFormLabel> </AppTheme>
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon color={darkMode&& "white"} icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer" />} />
                <AppTheme >
                <Field as={TextField} fullWidth type={visiblePass? "text": "password"} placeholder="Enter your password..." name="password" />
                </AppTheme>
                </InputGroup>
                <FormErrorMessage fontSize="small">
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="password"/>
                </FormErrorMessage>

                </FormControl>

                
                <HStack width="100%" justifyContent="space-between">
                <Field as={Checkbox} name="rememberMe" id="rememberMe">Remember me</Field>  
                <Text _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt={4}><span onClick={(e)=> navigate("/users/password/new")} style={{color:darkMode? "white":"hsl(210, 100%, 35%)", fontWeight: "bold", textDecoration: "underline",cursor: "pointer", marginLeft:5}}>Forgot pasword?</span></Text>    
              
                </HStack>
              
                
                <Button type="submit" isLoading={formik.isSubmitting} mt={4} width="full" colorScheme={darkMode? "gray":"messenger"}  fontWeight="bold" >Sign in</Button>
              

                </Form>
                )}
                </Formik>

  
                <AppTheme>

                <Typography sx={{ textAlign: 'center', marginTop:2,  }}>
              Don&apos;t have an account?{' '}
              <span>
                <Text fontSize="sm" as={RouterLink} to="/signup"
                  variant="body2"
                  sx={{ alignSelf: 'center' }}
                   _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}
                >
                  <span >Sign up</span>
                </Text>
              </span>
            </Typography>

            <Divider sx={{marginTop:1.5, marginBottom:1.5, }} >or</Divider>
          <BBox sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop:1 }}>
            <BButton
              type="submit"
              fullWidth
              variant="outlined"
              //onClick={() => alert('Sign in with Google')}
              startIcon={<GoogleIcon />}
              onClick={(e)=> {
                const clientId = 'Ov23liNLZ4caFoQvatDM'
                const secret = '287689496abf9deba6daeb5c721cdf334b2e3d79'
                // The encodeURIComponent() ensures URLs that contain special characters (like ?,&,=,/,etc.) data are safely encoded so the URL remains valid/can be used as part of the URL.
                //const githubUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectURI)}&scope=read:user user:email&response_type=code&state`
                window.location.href = "https://api.blogai.benasenso.com/accounts/google/login/"     
              }}
            >
              <span>Sign in with Google</span>
            </BButton>
            <BButton
              type="submit"
              fullWidth
              variant="outlined"
              //onClick={() => alert('Sign in with Facebook')}
              startIcon={<FacebookIcon />}
              //onClick={(e)=> window.location.href = " https://api.blogai.benasenso.com/accounts/facebook/login/"}
            >
              <span >Sign in with Facebook</span>
            </BButton>

            <BButton
              type="submit"
              fullWidth
              variant="outlined"
              //onClick={() => alert('Sign in with Facebook')}
              startIcon={<FontAwesomeIcon icon={faGithub} />}
              onClick={(e)=> window.location.href = "https://api.blogai.benasenso.com/accounts/github/login/"}
            >
              <span >Sign in with Github</span>
            </BButton>

            <BButton
              type="submit"
              fullWidth
              variant="outlined"
              startIcon={<XIcon />}
              onClick={(e)=> window.location.href = "https://api.blogai.benasenso.com/accounts/twitter/login/"}
            >
              <span >Sign in with Twitter</span>
            </BButton>
          </BBox>
          </AppTheme>


              </div>
              
            </div>
            
          


             <div>              
              {/* Purposely for Material Ui theme */}
               <AppTheme>
                <CssBaseline />
                <ColorModeSelect />
              </AppTheme> 
            </div>


            </HStack>

         
              
            </VStack>
 
             {/* <div>
              <SignInSide />
            </div> */}
        

          


                <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                preserveScrollBarGap={true}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor= {darkMode?"gray.700": "#FF8A65"}>
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
                {alerterrorResponseMessage}
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


               
            </div> 
        


                  
  
    )
  }


export default Signin