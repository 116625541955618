import { Heading, useBreakpointValue,Card, Center, CardBody, CardFooter, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel } from "@chakra-ui/react"
import {faWarning, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, FormLabel as MFormLabel } from "@mui/material";
import AppTheme from "./Components/MatSignIn/AppTheme";


import React, {useState, useEffect, useContext} from "react"
import CssBaseline from '@mui/material/CssBaseline';
import ColorModeSelect from "./Components/MatSignIn/ColorModeSelect";
import { useParams } from "react-router-dom";
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'

import { ref } from "yup";

import { useNavigate } from "react-router-dom";
import { MyContext } from "./Context/AuthContext";
import axios from 'axios'






function PasswordConfirm({successLogin, setsuccessLogin}){


  let navigate = useNavigate()
  let {darkMode, setDarkMode} = useContext(MyContext)


  let {uid, token} = useParams()  // Extract the uid parameter and the token parameter from the URL

  let [alertMessage, setAlertmessage] = useState("")
  let [alerterrorMessage, setAlerterrromessage] = useState("")
  let [visiblePass, setVisiblePass] = useState(false)


  

  let onClose = () => {
    setAlerterrromessage("")
  }


  let [initialValues, setInitialvalues] = useState({
    password: "",
    confirmPassword: ""
  })


  let validationSchema = Yup.object({
    password: Yup.string().required("Password required").min(8, "Password must be at least 8 characters").matches(/[0-9]/, "Password must contain at least one number").matches(/[!@#$%^&*-_]/, "Password must contain at least one special character"),
    confirmPassword: Yup.string().required("Confirm password required").oneOf([ref("password"), null], "Passwords must match"),    
  })


  
  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue ? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken();


  let onSubmit = async(values, actions) =>{
  
    try{  
      let response= await axios.post("https://api.blogai.benasenso.com/djoser/users/reset_password_confirm/", {
        uid: uid,
        token: token,
        new_password: values.password
      }, {
        headers:{
          'X-CSRFToken': csrftoken
        }
      })
      console.log("New password has been set")
      actions.setSubmitting(false)
      setAlertmessage("New Password reset has been set successfully")
      setTimeout(() => {
       setAlertmessage(false)
      }, 9000);
      actions.resetForm()
    }

    catch (error){
      console.error("There was an error setting new password", error.message)
      actions.setSubmitting(false)
      setAlerterrromessage(`Something went wrong, please try again later! ${error.message}`)
    }
   }



   let mobileView = useBreakpointValue({
    base: true,
    md: false
  })


  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
    lg: false
  })




    return(   
          <div>
              <VStack maxWidth="full" p={mobileView && 3} minHeight="100vh" alignItems="left" spacing={8}  backgroundColor={!darkMode&& "gray.100"} backgroundImage={darkMode&& "radial-gradient(at 70% 51%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))"} >  
      
             
              <div className="flex items-center justify-center min-h-screen">
                <div style={{border:darkMode&& "1px solid  hsl(220, 20%, 25%, 0.9)", backgroundImage:darkMode&& "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))", backgroundColor:darkMode? "#000":"white"}} className=" p-8 shadow-lg rounded-lg max-w-md w-full" >

                <Heading marginBottom={8} fontSize="2xl">Reset your Password. </Heading>

                
                {/*Creating Form here. nothing shoud interfere with the three f's ie Formik, formik sub and form*/}
                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form>
            
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mt={4} >               
                <AppTheme> <MFormLabel htmlFor="password">Password</MFormLabel> </AppTheme>
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer" />} />
                <AppTheme>
                <Field as={TextField} fullWidth autoFocus type={visiblePass? "text": "password"} placeholder="Enter your password..." name="password" width={mobileView? "100%":"sm"}  />
                </AppTheme>
                </InputGroup>
                <FormErrorMessage fontSize="small">
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="password"/>
                </FormErrorMessage>

                </FormControl>


                <FormControl isInvalid={!!formik.errors.confirmPassword && formik.touched.confirmPassword} mt={4} >               
                <AppTheme> <MFormLabel htmlFor="password">Confirm Password</MFormLabel> </AppTheme>
                <AppTheme>
                <Field as={TextField} fullWidth type="password" placeholder="Confirm your password..." name="confirmPassword" width={mobileView? "100%":"sm"}  />
                </AppTheme>
                <FormErrorMessage fontSize="small">
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="confirmPassword"/>
                </FormErrorMessage>

                </FormControl> 


                <Button type="submit" isLoading={formik.isSubmitting} mt={4} width="full" colorScheme={darkMode? "gray":"messenger"} fontWeight="bold" >Save</Button>
              
                </Form>
                )}
                </Formik>

                </div>
              </div>


              
              <div>              
              {/* Purposely for Material Ui theme */}
               <AppTheme>
                <CssBaseline />
                <ColorModeSelect />
              </AppTheme> 
              </div>

                </VStack>


                <AlertDialog
                isOpen={alertMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                preserveScrollBarGap={true}
                >

                <AlertDialogOverlay>
              <AlertDialogContent backgroundColor={darkMode?"gray.700": "#81C784"}>
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                All good!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alertMessage}<br/>
            
              </AlertDialogBody>
              </AlertDialogContent>
              </AlertDialogOverlay>

              </AlertDialog>


                <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                preserveScrollBarGap={true}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor={darkMode?"gray.700": "#FF8A65"}>
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


      
            </div> 

    )
  }


export default PasswordConfirm