import { Spinner, Center, Text, VStack } from "@chakra-ui/react"
import CssBaseline from '@mui/material/CssBaseline';
import ColorModeSelect from "./MatSignIn/ColorModeSelect";
import AppTheme from "./MatSignIn/AppTheme";


function Loadspinner({toggleProp}){

    return(
        <div>
            <Center minHeight="100vh" backgroundColor={toggleProp && "#18181b"} color={toggleProp && "white"} >
                <VStack>
                <Spinner size="xl" width={70} height={70} borderWidth={5} color="blue.600" />
                <Text fontSize="xl">Logging in...</Text>
                </VStack>

            </Center>



            
            <div>              
              {/* Purposely for Material Ui theme */}
               <AppTheme>
                <CssBaseline />
                <ColorModeSelect />
              </AppTheme> 
            </div>
            

        </div>
    )
}

export default Loadspinner