import { Heading, useBreakpointValue,Card, Center, CardBody, CardFooter, HStack, Container, Text, VStack, Button, Box, Image, InputAddon, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogCloseButton, InputGroup, InputRightElement, Checkbox, CardHeader, FormLabel } from "@chakra-ui/react"
import {faEye, faEyeSlash, faWarning} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, {useState, useEffect, useContext} from "react"
import {Formik, Form, Field, ErrorMessage } from "formik"
import {FormErrorMessage, FormControl, Input} from "@chakra-ui/react"
import * as Yup from 'yup'
import {ref} from 'yup'

import SignIn from "./Components/SignIn";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from "react-router-dom";

import { GoogleIcon, FacebookIcon, SitemarkIcon } from './Components/MatSignIn/CustomIcons';
import { Box as BBox, Divider, Button as BButton, TextField, FormLabel as MFormLabel} from "@mui/material";
import AppTheme from "./Components/MatSignIn/AppTheme";
import AIBlogBlue from "./Assets/AIBlog.png"
import CssBaseline from '@mui/material/CssBaseline';
import ColorModeSelect from "./Components/MatSignIn/ColorModeSelect";
import { MyContext } from "./Context/AuthContext";


import axios from "axios";

import { useNavigate } from "react-router-dom";

import useResetAuth from "./Components/ResetAuth";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'






function Signup(){

  let {darkMode, setDarkMode} = useContext(MyContext)


  
  useResetAuth()



  
  let mobileView = useBreakpointValue({
    base: true,
    lg: false
  })


  // This is to target the md or tablet/ipad view
  let tabletView = useBreakpointValue({
    md: true,
  })



  let navigate = useNavigate()
  let [alertMessage, setAlertmessage] = useState("")
  let [alerterrorMessage, setAlerterrromessage] = useState("")
  let [alerterrorResponseMessage, seterrorResponseMessage] = useState("")
  let [visiblePass, setVisiblePass] = useState(false)
  

  //let [rememberMe, setRememberMe] = useState(false)
  

  let onClose = ()=>{
    setAlertmessage("")
  }
  
  let onClose2 = () => {
    setAlerterrromessage("")
  }


  let initialValues = {
    username: "",
    email: "",
    password: "",
//  confirmPassword: "",
    emailUpdates: false
  }


  let validationSchema = Yup.object({
    username: Yup.string().required("Username required"),
    email: Yup.string().required("Email required").email("Invalid email format").matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email must be a valid email adddress"),
    password: Yup.string().required("Password required").min(8, "Password must be at least 8 characters").matches(/[0-9]/, "Password must contain at least one number").matches(/[!@#$%^&*-_]/, "Password must contain at least one special character"),
    // confirmPassword: Yup.string().required("Confirm password required").oneOf([ref("password"), null], "Passwords must match"),
    emailUpdates: Yup.string()
  })


  
  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue ? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken();

  
  let onSubmit = async(values, actions)=>{

    try{
     let response = await axios.post("https://api.blogai.benasenso.com/djoser/users/", values, {
      headers:{
        'X-CSRFToken': csrftoken
      }
     })
     console.log("Form submitted successfully")
     actions.setSubmitting(false)
     actions.resetForm()
     setAlertmessage(`Thanks for your submission ${values.username}, You have successfully registered on AI Blog! Check your email for the verification link `)
     setTimeout(() => {
      setAlertmessage(false)
     }, 9000)
    }

    catch (error){
      console.error("Error submitting data", error.message)
      actions.setSubmitting(false)
      setAlerterrromessage(`Something went wrong, please try again later! ${error.message}`)
      seterrorResponseMessage(error.response.data.username)
      setTimeout(() => {
        setAlerterrromessage(false)
      }, 9000);
    }
  }




    return(
      
        
          <div>
              <VStack maxWidth="full" minHeight="100vh" padding={mobileView?3: 20} alignItems="left" spacing={8}  backgroundColor={!darkMode&& "gray.100"} backgroundImage={darkMode&& "radial-gradient(at 70% 51%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))"} >  
                  

              <div className="flex items-center justify-center min-h-screen">
                <div style={{border:darkMode&& "1px solid  hsl(220, 20%, 25%, 0.9)", backgroundImage:darkMode&& "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))", backgroundColor:darkMode? "#000":"white"}} className=" p-8 shadow-lg rounded-lg max-w-md w-full" >
                {/* <Heading marginBottom={8} fontSize="2xl">Signup </Heading> */}

                <img src={AIBlogBlue} style={{width: "150px", padding:0,marginTop:-6}} />

                <AppTheme>
              {/* <SitemarkIcon /> */}
                <Typography
                  component="h1"
                  variant="h4"
                  sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)', marginTop:1, marginBottom:3, fontWeight:"bold"}}
                >
                  Sign Up
                </Typography>
                </AppTheme>


                <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >

                {(formik) => (

                <Form>

                <FormControl isInvalid={!!formik.errors.username && formik.touched.username} >
                <AppTheme> <MFormLabel htmlFor="username">Username</MFormLabel> </AppTheme>
                <AppTheme>
                <Field as={TextField} fullWidth autoFocus placeholder="Enter your username..."  name="username" id="username"  />
                </AppTheme>
                <FormErrorMessage fontSize="small">
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="username" />
                </FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!formik.errors.email && formik.touched.email} mt={4}>
                <AppTheme> <MFormLabel htmlFor="email">Email</MFormLabel> </AppTheme>
                <AppTheme>
                <Field as={TextField} fullWidth type="email" placeholder="Enter your email..." name="email"  />
                </AppTheme>
                <FormErrorMessage fontSize="small">
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="email" />
                </FormErrorMessage>

                </FormControl>
                
 
                <FormControl isInvalid={!!formik.errors.password && formik.touched.password} mt={4} >
               
              <AppTheme> <MFormLabel htmlFor="password">Password</MFormLabel> </AppTheme>
                <InputGroup>
                <InputRightElement children={<FontAwesomeIcon icon={visiblePass? faEyeSlash: faEye} onClick={(e)=> setVisiblePass(!visiblePass)} cursor="pointer" />} />
                <AppTheme>
                <Field as={TextField} fullWidth type={visiblePass? "text": "password"} placeholder="Enter your password..." name="password" />
                </AppTheme>
                </InputGroup>
                <FormErrorMessage fontSize="small">
                <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                <ErrorMessage name="password"/>
                </FormErrorMessage>

                </FormControl>


                <HStack width="100%" justifyContent="space-between" mt={6}>
                <Field as={Checkbox} name="emailUpdates" id="emailUpdates">I want to receive updates via email</Field>  
                {/* <Text _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} mt={4}><span onClick={(e)=> navigate("/users/password/new")} style={{color: "#EE9972", fontWeight: "bold", textDecoration: "underline",cursor: "pointer", marginLeft:5}}>Forgot pasword?</span></Text>     */}
              
                </HStack>

            
                <Button type="submit" isLoading={formik.isSubmitting} mt={6} width="full" colorScheme={darkMode? "gray":"messenger"} fontWeight="bold" >Sign Up</Button>
              

                </Form>
                )}
                </Formik>
                  <AppTheme>

                  <Typography sx={{ textAlign: 'center', marginTop:2 }}>
                    Already have an account?{' '}
                    <span>
                      <Text fontSize="sm" as={RouterLink} to="/signin"
                        variant="body2"
                        sx={{ alignSelf: 'center' }}
                        _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}
                      >
                        <span >Sign in</span>
                      </Text>
                    </span>
                  </Typography>

                  <Divider sx={{marginTop:1.5, marginBottom:1.5}} >or</Divider>
                  <BBox sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop:1 }}>
                    <BButton
                      type="submit"
                      fullWidth
                      variant="outlined"
                      //onClick={() => alert('Sign in with Google')}
                      startIcon={<GoogleIcon />}
                      onClick={(e)=> {
                        const clientId = 'Ov23liNLZ4caFoQvatDM'
                        const secret = '287689496abf9deba6daeb5c721cdf334b2e3d79'
                        // The encodeURIComponent() ensures URLs that contain special characters (like ?,&,=,/,etc.) data are safely encoded so the URL remains valid/can be used as part of the URL.
                        //const githubUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectURI)}&scope=read:user user:email&response_type=code&state`
                        window.location.href = "https://api.blogai.benasenso.com/accounts/google/login/"     
                      }}
                    >
                      <span >Sign up with Google</span>
                    </BButton>
                    <BButton
                      type="submit"
                      fullWidth
                      variant="outlined"
                      //onClick={() => alert('Sign in with Facebook')}
                      startIcon={<FacebookIcon />}
                      //onClick={(e)=> window.location.href = "https://api.blogai.benasenso.com/accounts/facebook/login/"}
                    >
                      <span>Sign up with Facebook</span>
                    </BButton>

                  </BBox>
                  </AppTheme>

                </div>
              </div>


              
             <div>              
              {/* Purposely for Material Ui theme */}
               <AppTheme>
                <CssBaseline />
                <ColorModeSelect />
              </AppTheme> 
            </div>


              {/* <div>
            <SignIn />
            </div> */}


                </VStack>


                
                <AlertDialog
                isOpen={alertMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose}
                preserveScrollBarGap={true}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor={darkMode?"gray.700": "#81C784"}>
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                All good!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alertMessage}<br/>
            
              </AlertDialogBody>
              </AlertDialogContent>
              </AlertDialogOverlay>

              </AlertDialog>
                  
                
                
                <AlertDialog
                isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
                onClose={onClose2}
                preserveScrollBarGap={true}
                >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor={darkMode?"gray.700": "#FF8A65"}>
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5} >
                {alerterrorMessage}<br/>
                {alerterrorResponseMessage && alerterrorResponseMessage}
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


             
               
            </div> 


                  
  
    )
  }


export default Signup