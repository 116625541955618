import { Container, Center, Button, Box,Text, Skeleton, SkeletonText, Heading, VStack, HStack, useBreakpointValue } from "@chakra-ui/react"
import { useState, useEffect, useContext } from "react"
import { MyContext } from "./Context/AuthContext"
import {Link} from 'react-router-dom'
import {format} from "date-fns"
import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faBlog} from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"



import CssBaseline from '@mui/material/CssBaseline';
import ColorModeSelect from "./Components/MatSignIn/ColorModeSelect";
import AppTheme from "./Components/MatSignIn/AppTheme";



function AllBlogs({blogId, setBlogId}){

let navigate = useNavigate()
let {darkMode, setDarkMode} = useContext(MyContext)


let [userAllBlogs, setuserAllBlogs] = useState([])
let [nextPage, setNextPage] = useState(1)
let [hasMore, setHasMore] = useState()
let [dataCount, setDataCount] = useState()



        let func = async(e)=>{
            try{
                let response = await axios.get(`https://api.blogai.benasenso.com/api/user-blog/?page=${nextPage}`, {
                    headers:{
                        'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`
                    }
                })
                console.log(response.data.results)
                setuserAllBlogs(prev => [...prev, ...response.data.results])
                setDataCount(response.data.count)
                setHasMore(response.data.next)
                setNextPage(prev => prev+1)
                
            
            }
            catch (error){
                console.error("Error fetching user blogs", error.response.data)
            }
        }




    useEffect((e)=>{
        func()
    }, [])




    let mobileView = useBreakpointValue({
        base: true,
        md: false
    })


    let tabletView = useBreakpointValue({
        md:true,
        lg:false
    })
    

    return(
        <Box className={mobileView? "mx-auto px-3 sm:px-0":"mx-auto px-4 sm:px-0"}  backgroundColor={!darkMode&& "gray.100"} backgroundImage={darkMode&& "radial-gradient(at 70% 51%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))"}>
            <VStack maxWidth="full" minHeight="100vh" alignItems="left" spacing={8} p={mobileView?0: 10} >  
            
            <div className={mobileView? "p-4 rounded-lg shadow-lg": "p-8 rounded-lg shadow-lg"} style={{marginTop:120, marginBottom:120, border:darkMode&& "1px solid hsl(220, 20%, 25%, 0.9)", backgroundImage:darkMode&& "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))", backgroundColor:darkMode? "#000":"white"}}>   

            <Heading as="h2" size={mobileView? "lg": "xl"} >All Blog Posts</Heading>
            {/* <div className="space-y-4 my-4">
                <div className="border border-black p-4 rounded">
                    <h3 className="text-2xl font-semibold">Blog Post Title 1</h3>
                    <p className="text-xl">This is the blog content for the blog post</p>


                </div>
            </div> */}

            {userAllBlogs && userAllBlogs.length>0?
            <InfiniteScroll dataLength={userAllBlogs.length} next={func} hasMore={hasMore} 
            loader={<h4>Loading...</h4>} 
            endMessage={<p>All blogs loaded!</p>}> 

            {/* .reverse() to reverse order of items in an array before applying .map() */}
            {userAllBlogs.reverse().map((f, index)=>{
                return  <div key={index} className="space-y-4 my-4">
                <div className={darkMode? "border border-white p-4 rounded": "border border-black p-4 rounded"}>
                    <HStack flexDirection={mobileView && "column"} alignItems={mobileView && "start"} width="100%" justifyContent="space-between" >  
                    <Link to={"/blog-details/" + f.id}>   {/* Use this .replace(/\s+/g, "-") if you want to replace spaces with '-' in the URL */}
                    <Text as="h1" onClick={(e)=>setBlogId(f.id)} size="md" _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} className={mobileView? "text-xl font-semibold":"text-2xl font-semibold"}>{f.youtube_title}</Text>
                     </Link>
                    <h3 className={mobileView? "text-lg font-semibold":"text-xl font-semibold"}>{format(f.created_at, "yyyy-MM-dd 'at' hh:mm a")}</h3>

                    </HStack>
                    <Text dangerouslySetInnerHTML={{__html: f.generated_blog}} className={mobileView? "text-lg":"text-xl"} noOfLines={mobileView?6 :4} ></Text>
                </div>
            </div>
            })}
            </InfiniteScroll>
            
            :
            
            (userAllBlogs&& dataCount===0?
            <Center minHeight="50vh">
                <VStack>
                <FontAwesomeIcon icon={faBlog} size={mobileView?"4x": (tabletView?"6x" : "9x")} />
                <Heading fontSize={tabletView? "3xl": (mobileView? "large": "4xl")} >You don't have any blog posts yet.</Heading>
                <Button mt={4} colorScheme="facebook" onClick={(e) => navigate("/dashboard")}   >Create Your First Blog Post</Button>
                </VStack>
            </Center>
            
            :

             <>
             <Box padding='6' boxShadow="lg" >
                 <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='4' />
             </Box>

             <Box padding='6' boxShadow="lg" >
                 <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='4' />
             </Box>
             </> 
            ) }

            
            

            
            

  

            </div>


            </VStack>


            <div>              
              {/* Purposely for Material Ui theme */}
               <AppTheme>
                <CssBaseline />
                <ColorModeSelect />
              </AppTheme> 
            </div>


        </Box>
    )
}


export default AllBlogs