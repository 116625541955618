import * as React from 'react';
import { useColorScheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Button } from '@chakra-ui/react';
import { MyContext } from '../../Context/AuthContext';
import {useEffect, useContext} from 'react'


export default function ColorModeSelect(props) {
  let {darkMode, setDarkMode} = useContext(MyContext)
  
  const { mode, setMode } = useColorScheme();
   //if (!mode) {
  //   return null;
//   }

  useEffect((e)=>{
    if(darkMode){
      setMode("dark")
    }
    else{
      setMode("light")
    }

  }, [darkMode])


  return (
    <div>
    {/* <Select
      value={mode}
      onChange={(e) => setMode(e.target.value)}
      SelectDisplayProps={{
        'data-screenshot': 'toggle-mode',
      }}
      {...props}
    >
      <MenuItem value="system">System</MenuItem>
      <MenuItem value="light">Light</MenuItem>
      <MenuItem value="dark">Dark</MenuItem>

    </Select>
     */}

    </div>

  );
}
