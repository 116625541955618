import React, {useContext, useEffect, useState} from "react";
import {useBreakpointValue,Box, Flex, CircularProgress, FormControl,Input,Center,Heading,Image,VStack, SimpleGrid, Card, Text, Link,CardHeader, CardBody, LinkBox, LinkOverlay, IconButton, AvatarGroup, Avatar, CardFooter, FormErrorMessage, Button, HStack} from "@chakra-ui/react";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import {useSpring ,animated} from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogCloseButton } from "@chakra-ui/react";
import {Formik, Field, ErrorMessage, Form} from 'formik'
import * as Yup from 'yup'
import { useLocation, useNavigate } from "react-router-dom";
import { MyContext } from "./Context/AuthContext";
import axios from 'axios'


import CssBaseline from '@mui/material/CssBaseline';
import ColorModeSelect from "./Components/MatSignIn/ColorModeSelect";
import AppTheme from "./Components/MatSignIn/AppTheme";




function Welcome({toggleProp, setToggle, successLogin, setsuccessLogin}){

let {isAuthenticated, setIsAuthenticated} = useContext(MyContext)
let {darkMode, setDarkMode} = useContext(MyContext)

let [progress, setProgress] = useState(false)
let [requestResponse, setRequestResponse] = useState("")

let [alerterrorMessage, setAlerterrormessage] = useState("")
let [alerterrorResponseMessage, seterrorResponseMessage] = useState("")

let onClose = () => {
  setAlerterrormessage("")
}


let [initialValues, setInitialValues ] = useState({
  youtubeLink: ""
})


let validationSchema = Yup.object({
  youtubeLink: Yup.string().url("Invalid url format").required(" Youtube link is required")
})


// Function to suppose to get django default csrf_token from cookie in browser when React is served on django
function getCSRFToken(){
  let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
  return cookieValue? cookieValue[1] : null
}

let csrftoken = getCSRFToken()



let onSubmit = async(values, actions)=>{
  setProgress(true)
  let youtubeLink = document.getElementById("youtubeLink").value
  let blogContent = document.getElementById("blogContent")
  if (youtubeLink){
    //blogContent.innerHTML = "" // This clear / remove previous content
    let endpointUrl = "/generate-blog"

    try{
       // I have to send json data as payload to request method body. But axios will automatically do it for me
      let response = await axios.post("https://api.blogai.benasenso.com/api/generate-blog/", {link: youtubeLink}, {
        headers: {
          'Authorization': `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
          'X-CSRFToken': csrftoken
        }
      })
      setRequestResponse(response.data.content)
      console.log("Success getting request response", response.data)
      //let data = await response.json()
      //blogContent.innerHTML = requestResponse.content
      setProgress(false)
      actions.setSubmitting(false)
    }
    catch (error){
      setProgress(false)
      console.error("Error here:", error.message)
      setAlerterrormessage(`Something went wrong, please try again later! ${error.message}`)
      console.error("There was an error sending youtube link", error.response.data)
      setRequestResponse(error.response.data.errormessage)
      actions.setSubmitting(false)
    }
  }

}


// Check authentication status
// {withCredentials:true} means I'm using session cookies for authentication/Authorization and not DRF token
// Session cookies comes from django and anytime it is sent back to Django with each request, django knows the user making the request
// useEffect((e)=>{
//   let func = async(e)=>{
//     try{
//       let response = await axios.get(" https://api.blogai.benasenso.com/api/check-auth-status", {
//         headers: {
//           'X-CSRFToken': csrftoken
//         }
//       },{withCredentials: true}) 
//       console.log("User Authentication status:", response)
//       //if (response.data.isAuthenticated){
//         //setIsAuthenticated(true)
//         // setsuccessLogin(response.data.token)
//         // localStorage.setItem("UserAuthtoken", JSON.stringify(response.data.token))
//       //}
//     }
//     catch (error){
//       //setIsAuthenticated(false)
//       console.error("Error checking Authentication status:", error.message)
//     }
//   }

//   func()
  
// }, [])





let mobileView = useBreakpointValue({
  base: true,
  md: false
})


let tabletView = useBreakpointValue({
  md: true, 
  lg: false
})


if (mobileView){
  return(
    <div>
       <VStack maxWidth="full"  p={3}  minHeight="100vh" alignItems="left"  backgroundColor={!darkMode&& "gray.100"} backgroundImage={darkMode&& "radial-gradient(at 70% 51%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))"}>  
 
        
        <Card color={darkMode && "white"} className="max-w-md mx-auto my-auto shadow-lg" size="sm" mt={20} p={4} shadow="2xl" transition="transform 0.3s ease-in-out"  style={{border:darkMode&& "1px solid hsl(220, 20%, 25%, 0.9)", backgroundImage:darkMode&& "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))", backgroundColor:darkMode? "#000":"white"}}>
          <CardHeader>
        <Heading as="h2" textAlign="center" size="large"  id="about" >Welcome to the AI Blog Generator</Heading>
          </CardHeader>
        
        <CardBody mt={0} pt={0} >
        <Text fontSize="lg"  textAlign="center" className="text-grey-700" style={{margin: 5}}>Generate high-quality blog articles from YouTube videos using artificial intelligence.
        Simply enter the link to the YouTube video below and let the AI create the content for you!
        </Text> <br/>

        <Heading mt={0} size="md" >Enter Youtube Video Link</Heading>
         
        </CardBody> 
        
        <CardFooter  pt={0}>
          <VStack width="full" alignItems="stretch">
          <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
            {(formik)=>(
              <Form>
            
                <FormControl isInvalid={!!formik.errors.youtubeLink && formik.touched.youtubeLink} >
                  <Input as={Field}  name="youtubeLink" id="youtubeLink" width="full" placeholder="Paste YouTube Link..." />
                  <FormErrorMessage>
                  <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                  <ErrorMessage name="youtubeLink" />
                  </FormErrorMessage>

                </FormControl>
                
                <Button width="full" mt={5} borderRadius={10} p={3} colorScheme={darkMode? "gray":"facebook"} fontWeight="bold" type="submit" isDisabled={formik.isSubmitting}>Generate</Button>
              

              </Form>
            )}
          </Formik>

          
          {progress &&
            <Center  minHeight="25vh">
              <CircularProgress position="absolute" style={{zIndex: 1}} top={80} transform="translate(0.5%,100.5%)" value={80} size="120px" isIndeterminate/>
            </Center> }

            <Heading alignSelf="start" mt={8} size="md" >Generated Blog Article</Heading>
            <div id="blogContent" className="mt-2 text-gray-700 space-y-4">
                            
              {requestResponse &&
                <>
                {/* <Text fontWeight="semibold" fontSize="xl">Unable to generate blog article: Transcript for youtube audio file is below</Text> */}
                {requestResponse.split(/(?:\r\n|\r|\n){2,}/).map(f=>(
                  <Text fontSize="lg" className={darkMode? "text-gray-400": "text-grey-700"} >{f}</Text>
                ))}
                </>
                
              }
            </div>
          </VStack>

        </CardFooter>
        
        </Card>

        </VStack>



              <AlertDialog
              isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
              onClose={onClose}
              >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor={darkMode?"gray.700": "#FF8A65"}>
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
                {alerterrorResponseMessage}
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


            <div>              
              {/* Purposely for Material Ui theme */}
               <AppTheme>
                <CssBaseline />
                <ColorModeSelect />
              </AppTheme> 
            </div>



    </div>
  )
}

  
else{


  return(
    <div>
        <VStack maxWidth="full"  minHeight="100vh" alignItems="left" backgroundColor={!darkMode&& "gray.100"} backgroundImage={darkMode&& "radial-gradient(at 70% 51%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))"} >  
 
        
        <Card color={darkMode && "white"} className="max-w-4xl mx-auto my-auto shadow-lg" width={tabletView && "2xl"} size="sm" mt={20} p={6} shadow="2xl" _hover={{transform: !requestResponse && "scale(1.1)"}} transition="transform 0.3s ease-in-out"  style={{border:darkMode&& "1px solid hsl(220, 20%, 25%, 0.9)", backgroundImage:darkMode&& "radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))", backgroundColor:darkMode? "#000":"white"}}>
          <CardHeader>
        <Heading as="h2" textAlign="center" size="large"  id="about" >Welcome to the AI Blog Generator</Heading>
          </CardHeader>
        
        <CardBody mt={0} pt={0} >
        <Text fontSize="lg"  textAlign="center" className="text-grey-700" style={{margin: 5}}>Generate high-quality blog articles from YouTube videos using artificial intelligence.
        Simply enter the link to the YouTube video below and let the AI create the content for you!
        </Text> <br/>

        <Heading mt={0} size="md" >Enter Youtube Video Link</Heading>
         
        </CardBody> 
        
        <CardFooter  pt={0}>
          <VStack>
          <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}>
            {(formik)=>(
              <Form>
                <Flex>
                <FormControl isInvalid={!!formik.errors.youtubeLink && formik.touched.youtubeLink} >
                  <Input as={Field}  name="youtubeLink" id="youtubeLink" width={tabletView?"md":"2xl"} placeholder="Paste YouTube Link..." />
                  <FormErrorMessage>
                  <span style={{marginRight:5}}><FontAwesomeIcon icon={faWarning}/> </span>
                  <ErrorMessage name="youtubeLink" />
                  </FormErrorMessage>

                </FormControl>
                
                <Button borderRadius={10} p={3} paddingLeft={10} paddingRight={10} colorScheme={darkMode? "gray":"facebook"} fontWeight="bold" type="submit" isDisabled={formik.isSubmitting}>Generate</Button>
                </Flex>

              </Form>
            )}
          </Formik>

          
          {progress &&
            <Center  minHeight="20vh">
              <CircularProgress position="absolute" style={{zIndex: 1}} top={80} transform="translate(0.5%,0.5%)" value={80} size="120px" isIndeterminate/>
            </Center> }

            <Heading alignSelf="start" mt={8} size="md" >Generated Blog Article</Heading>
            <div id="blogContent" className="mt-2 text-gray-700 space-y-4">
                            
              {requestResponse &&
                <>
                {/* <Text fontWeight="semibold" fontSize="xl">Unable to generate blog article: Transcript for youtube audio file is below</Text> */}
                {requestResponse.split(/(?:\r\n|\r|\n){2,}/).map((f)=>(
                  <Text fontSize="lg" className={darkMode? "text-gray-400": "text-grey-700"} >{f}</Text>
                ))}
                </>
                
              }
            </div>
          </VStack>

        </CardFooter>
        
        </Card>


        </VStack>  
      


              <AlertDialog
              isOpen={alerterrorMessage}  /* What will make it open is the alertMessage variable*/
              onClose={onClose}
              preserveScrollBarGap={true}
              >

              <AlertDialogOverlay>
              <AlertDialogContent backgroundColor={darkMode?"gray.700": "#FF8A65"}>
              <AlertDialogHeader paddingTop={5} fontWeight="bold">
              <AlertDialogCloseButton/>  
                Oops!
              </AlertDialogHeader >
              <AlertDialogBody paddingBottom={5}>
                {alerterrorMessage}<br/>
                {alerterrorResponseMessage}
            
            </AlertDialogBody>
            </AlertDialogContent>
            </AlertDialogOverlay>

            </AlertDialog>


            {/* This is what creates the growing and shrinking of the container, mx-auto creates horizontal margins automatically to adjust */}
            {/* <div className="flex-grow container mx-auto mt-10 px-4 sm:px-0 bg-gray-300 shadow-lg"> 
              <div className="max-w-3xl mx-auto bg-white p-6 rounded-lg"> 
                <div className="text-center">
                  <h2 className="text-2xl font-semibold mb-4">Welcome to the AI Blog Generator</h2>
                  <p className="text-gray-700 text-xl">Generate high-quality blog articles from YouTube videos using artificial intelligence. Simply enter the link to the YouTube video below and let the AI create the content for you!</p>
                  <Heading mt={0} size="md" >Enter Youtube Video Link</Heading>
                </div>
              </div>
            </div> */}


            <div>              
              {/* Purposely for Material Ui theme */}
               <AppTheme>
                <CssBaseline />
                <ColorModeSelect />
              </AppTheme> 
            </div>

        
    </div>
  )
}

}



export default Welcome;
