import { useContext, useEffect } from "react";
import { MyContext } from "../Context/AuthContext";



let useResetAuth = ()=>{
    let {isAuthenticated, setIsAuthenticated} = useContext(MyContext)

    useEffect((e)=>{
        setIsAuthenticated(false)
        localStorage.removeItem("UserAuthtoken")
    }, [setIsAuthenticated])

}


export default useResetAuth