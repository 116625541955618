import React, {useState, useEffect, useRef, useContext } from "react";
import { MyContext } from "./Context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSignOut, faBars, faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import {faGithub,faInstagram, faLinkedin,faMedium,faStackOverflow,faTwitter,} from "@fortawesome/free-brands-svg-icons";
import {useNavigate, useLocation,Link as RouterLink} from "react-router-dom"
import AIBlog from "./Assets/AI-Blog-Generator-Logo.png"
import {HashLink} from "react-router-hash-link"
import { Box, useToast,Center,Text,HStack, Heading,VStack, Image, Link, Switch,useBreakpointValue,IconButton, Button, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerCloseButton, DrawerBody, transition, Skeleton, useConst} from "@chakra-ui/react";
import { IconButton as IconB } from "@mui/material";
import XIcon from "@mui/icons-material/X"

import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import WbSunnyRoundedIcon from '@mui/icons-material/WbSunnyRounded';

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import axios from "axios";





function Header ({toggleProp, setToggle, selected, setSelected, successLogin, setsuccessLogin}){



  let {isAuthenticated, setIsAuthenticated} = useContext(MyContext)
  let {darkMode, setDarkMode} = useContext(MyContext)


  let navigate = useNavigate()
  let chaktoast = useToast()






 //Creating visibility of the menu depending on Scroll
 let [scrollUp, setscrollUp] = useState(true) 
 let [prevScroll, setprevScroll] = useState(0)

 useEffect((e) => {
  let  handleScroll=() => {
  let verticalScroll = window.scrollY

  setscrollUp(verticalScroll < prevScroll) // This is comparing the previous scroll with the current scroll ie verticalScroll
  setprevScroll(verticalScroll) //this now update the prevSroll(0) with the vertical scroll position number & the comparison continues 
}

  window.addEventListener("scroll", handleScroll)
//Remove Event listener to save Memory when the component is unmounted/Or no longer rendered in the current view/Or user navigates away from page
//The return is a cleanup function for all your cleanup activities in useEffect
return () => {
  window.removeEventListener("scroll", handleScroll)
}
 }, [ prevScroll]) 





  let [token, setToken] = useState()
  let [user, setUser] = useState()

  useEffect((e)=>{

    let func = async(e)=>{
      if (isAuthenticated){
      try{
        let response = await axios.get("https://api.blogai.benasenso.com/djoser/users/me/", {
          headers: {
            'Authorization': `token ${successLogin}`,
          },
        });
        console.log("Fetch user data successfully:", response.data)
        setUser(response.data)
      }
      catch(error){
        console.error("Error fetching data", error.response.data)
      }
    }
    }

    func()

  }, [successLogin, isAuthenticated])

  
  // Function to suppose to get django default csrf_token from cookie in browser when React is served on django
  function getCSRFToken(){
    let cookieValue = document.cookie.match(/csrftoken=([^ ;]+)/)
    return cookieValue? cookieValue[1] : null
  }

  let csrftoken = getCSRFToken()



let submitSignout = async(e)=>{
  
  const loading = toast.loading("Logging out...")

  try{
  //let auth = getAuth(app)
  //await signOut(auth)
  // The null indicates that no data is been sent to the request body else axios will take headers as the data
  const response = await axios.post("https://api.blogai.benasenso.com/djoser/token/logout", null,{
    headers: {
      "Authorization": `token ${JSON.parse(localStorage.getItem("UserAuthtoken"))}`,
      'X-CSRFToken': csrftoken
    }
  })
  localStorage.removeItem("UserLogin")
  localStorage.removeItem("UserAuthtoken")
  setsuccessLogin(false)
  setIsAuthenticated(false)
  console.log("You've been logged out successfully:", response.data)
  toast.dismiss(loading)
  // navigate("/signin", {replace:true})
  setSelected("Signin")
  chaktoast({
    title: "Logged Out",
    description: "You were logged out of your session!",
    status: "info",
    duration: 5000,
    position: "top-right",
    isClosable: true
  })

  }

  catch(error){
    console.error("There was an error signing out:", error.message)
    console.error("Error signing out:", error.response.data)
  }
}




 //Creating a Hamburger Menu / base represents mobile & md represents desktop
 let mobileView = useBreakpointValue({
  base: true,
  lg: false
 })


 let tabletView = useBreakpointValue({
  md: true,
  lg:false
  
 })


 
 let Hstyle = {

  position: "fixed",
  //background: "#18181b",
  color: "white",
  padding: tabletView? "10px": (mobileView?"10px":"0px"),
  right:"0",
  left:"0",
  top: "0",
  transition: "top 0.3s ease-in-out",
  zIndex: 5
 }


 let [isOpen, setIsOpen] = useState(false)

 let onClose = ()=>{
  return setIsOpen(false)
 }

 
 if (mobileView) {
  return (
    <div style={Hstyle}>

      {scrollUp &&
    <HStack className={darkMode? "bg-gray-900": "bg-blue-600"}  style={Hstyle} spacing={tabletView? 10:8} paddingLeft={tabletView? 4: 1} paddingRight={tabletView? 4: 1} color="white" right="0">
      <Box ml={mobileView?1: 8}>
      {/* <Heading size="md" >AI Blog Generator</Heading>   */}
      <Image src={AIBlog} width={mobileView?160: 300}  />
      </Box>
      
     
      

      <HStack marginLeft="auto" spacing={mobileView?5 :10}> 

        <IconB onClick={(e)=> setDarkMode(!darkMode)}>
         {darkMode? <WbSunnyRoundedIcon color="primary" aria-label="Theme toggle button"/> : <ModeNightRoundedIcon />}

        </IconB>

        <Text fontSize={mobileView?"sm": "md"} className="my-auto" >
            {successLogin && `Welcome ${user? user?.username: ""}`}
        </Text> 
        {(isAuthenticated&&!user) && <Text mx={-6} as={Skeleton}>Username</Text>}

  


      <IconButton as={IconB}  icon= {<FontAwesomeIcon icon={faBars} size="xl"  />} onClick={(e)=>{setIsOpen(true)}} />
      
    </HStack>
    
       
      
    </HStack>}

    <Drawer 
    isOpen={isOpen}
    onClose={onClose}
    placement="top" >
      <DrawerOverlay>
        <DrawerContent bg={darkMode? "gray.900": "linear-gradient(to bottom right, #004e8c, white)"}>
         <DrawerCloseButton color={darkMode? "white": "black"} size="lg" />
          
         <DrawerHeader bg={toggleProp && "#18181b"} color= "#EDEFEE">
          {/*  <Image src={logo} maxWidth="20vw"  /> */}
            <Link >
            <Image src={AIBlog} alt="Ben Asenso Web Developer and Digital Marketing Freelancer" title="Ben Asenso Web Developer and Digital Marketing Freelancer" boxSize="250px" height={14}  /> 
            </Link>
            
          </DrawerHeader>

          <DrawerBody color={toggleProp && "white"}  pt={tabletView && 10}>
            <VStack>
             
          

          {isAuthenticated?
            <>
            <Button variant="ghost" transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={darkMode && "white"}  style={{ background: selected == "Dashboard"? "#2a4365" : "", color: selected == "Dashboard"? "white" :""}} onClick={(e)=> {   
               navigate("/dashboard", {replace:true})
               setSelected("Dashboard")

            }}>Dashboard</Button>      

            <Button variant= "ghost" mt={tabletView && 8} transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={darkMode && "white"}  style={{ background: selected == "All-blogs"? "#2a4365" : "", color: selected == "All-blogs"? "white" :""}} onClick={(e)=> {
              navigate("/all-blogs",{replace:true})
              setSelected("All-blogs")

            }}>Saved Blog Posts</Button>

             <Button variant= "ghost" mt={tabletView && 8} transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={darkMode && "white"}  style={{ background: selected == "Logout"? "#2a4365" : "", color: selected == "Logout"? "white" :""}} onClick={(e)=> {
              setSelected("Logout")
              submitSignout()
              setIsOpen(false)
              
            }}><FontAwesomeIcon icon={faSignOut}/><span style={{marginLeft: 10}}>
              Log Out</span></Button>       
            </>
            :

            <>
             <Button variant= "ghost" mt={tabletView && 8} transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={darkMode && "white"}  style={{ background: selected == "Signin"? "#2a4365" : "", color: selected == "Signin"? "white" :""}} onClick={(e)=> {
              navigate("/signin", {replace:true})
              setSelected("Signin")

            }}>Login</Button>

            <Button variant= "ghost" mt={tabletView && 8} transform= {tabletView? "scale(1.8)": "scale(1.2)"} size="lg" color={darkMode && "white"}  style={{ background: selected == "Signup"? "#2a4365" : "", color: selected == "Signup"? "white" :""}} onClick={(e)=> {
              navigate("/signup", {replace:true})
              setSelected("Signup")

            }}>Signup</Button>
            </>}

      
          

           
            <HStack spacing={8} mt={20}>
            <Link href="https://www.stackoverflow.com/benasenso" isExternal mt="30"  _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
            <FontAwesomeIcon icon={faStackOverflow} size="2xl" />
            </Link>
            <Link href="https://www.twitter.com/Ararat_Ben" isExternal mt="30"  _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
            <XIcon  style={{fontSize: 30}} />
            </Link>
            <Link href="https://www.Instagram.com/bengizzlettl" isExternal mt="30"  _active={{bg: "rgba(34,167,240,0.5)", color: "red"}}>
            <FontAwesomeIcon icon={faInstagram} size="2xl" />
            </Link>
            <Link href="https://www.medium.com/benasenso" isExternal mt="30"  _active={{bg: "rgba(34,167,240,0.5)", color: "red"}} >
            <FontAwesomeIcon icon={faMedium} size="2xl" />
            </Link>
            
           
            </HStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
    </div>
  )
 }

 else {
  return(
    <div >
      {scrollUp &&
      <HStack className={darkMode? "bg-gray-900": "bg-blue-600"}  spacing={8}  style={Hstyle} >
      
      <Box ml={8}>
      {/* <Heading >AI Blog Generator</Heading>   */}
      <Image src={AIBlog} width={360}  />
      </Box>
      

      <Box marginLeft="auto" marginRight="10">
      <HStack spacing={8} alignSelf="right">

        <IconB onClick={(e)=> setDarkMode(!darkMode)}>
         {darkMode? <WbSunnyRoundedIcon color="primary" aria-label="Theme toggle button" /> : <ModeNightRoundedIcon/>}

        </IconB>

      <Text cursor={successLogin && "default"}  as={RouterLink} to= {!successLogin && "/signin"} _hover={{fontWeight: !successLogin && "bold", textDecoration: !successLogin && "underline"}} >
     {successLogin&&isAuthenticated? `Welcome ${user? user?.username: ""}` : "Login"}
      </Text>
      {(isAuthenticated&&!user) && <Text mx={-6} as={Skeleton}>Username</Text>}


  
  {isAuthenticated? 
      
      <>
      <Text as={RouterLink} to="/dashboard" _hover={{fontWeight: "bold", textDecoration: "underline"}} >
      Dashboard
      </Text>

      <Text as={RouterLink} to="/all-blogs" _hover={{fontWeight: "bold", textDecoration: "underline"}} >
      Saved Blog Posts
      </Text>

      <Link onClick={submitSignout} _hover={{fontWeight: "bold", textDecoration: "underline"}} >
      Logout
      </Link> 
      </>
      :
      <Text as={RouterLink} to="/signup" _hover={{fontWeight: "bold", textDecoration: "underline"}} >
        Signup
      </Text> }

    
     
      </HStack>
      </Box>

      </HStack>}
  </div>
  )
 }

} 
  
export default Header 
 